<template>
  <NuxtLink to="/" class="app-header-logo" title="nowoczesna stodoła">
    <!-- DESKTOP LOGO -->
    <svg
      alt="Logo"
      class="h-4 w-auto hidden lg:block"
      width="255"
      height="16"
      viewBox="0 0 255 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2227 5.34977C12.115 6.20026 12.5611 7.4874 12.5611 9.20738V15.8139H8.42578V9.86804C8.42578 8.29234 7.77794 7.50259 6.48614 7.50259C5.77623 7.50259 5.20598 7.7304 4.77538 8.18603C4.34866 8.64165 4.1353 9.32129 4.1353 10.2325V15.8139H0V4.26006H3.94134V5.51683C4.40685 5.04981 4.95771 4.68911 5.59779 4.44231C6.23787 4.19552 6.93226 4.07022 7.68871 4.07022C9.15508 4.07022 10.3344 4.49547 11.2266 5.34977H11.2227Z"
        fill="black"
      />
      <path
        d="M18.9192 15.233C17.9028 14.7204 17.1076 14.0104 16.5334 13.103C15.9593 12.1955 15.6722 11.1628 15.6722 10.0123C15.6722 8.86187 15.9593 7.8519 16.5334 6.94445C17.1076 6.037 17.8989 5.32698 18.9076 4.8258C19.9162 4.32081 21.0605 4.07022 22.3368 4.07022C23.6131 4.07022 24.7614 4.32461 25.7777 4.8258C26.7941 5.33078 27.5855 6.0332 28.1519 6.93686C28.7182 7.84051 29.0014 8.86566 29.0014 10.0161C29.0014 11.1666 28.7182 12.1955 28.1519 13.1068C27.5855 14.0142 26.7941 14.7242 25.7777 15.2368C24.7614 15.7494 23.6131 16.0038 22.3368 16.0038C21.0605 16.0038 19.9356 15.7494 18.9192 15.2368V15.233ZM24.1213 12.0512C24.5868 11.5614 24.8196 10.8818 24.8196 10.0161C24.8196 9.15043 24.5868 8.49737 24.1213 8.01137C23.6558 7.52917 23.0623 7.28617 22.3368 7.28617C21.6114 7.28617 21.014 7.52917 20.5524 8.01137C20.0869 8.49357 19.8541 9.16182 19.8541 10.0161C19.8541 10.8704 20.0869 11.5614 20.5524 12.0512C21.0179 12.541 21.6114 12.7878 22.3368 12.7878C23.0623 12.7878 23.6558 12.541 24.1213 12.0512Z"
        fill="black"
      />
      <path
        d="M51.2219 4.26007L47.0206 15.8101H43.0134L40.7944 9.52633L38.4862 15.8101H34.479L30.2777 4.26007H34.1958L36.6126 11.2501L39.1147 4.26007H42.6409L45.081 11.3109L47.5831 4.26007H51.218H51.2219Z"
        fill="black"
      />
      <path
        d="M55.7529 15.233C54.7365 14.7204 53.9413 14.0104 53.3671 13.103C52.793 12.1955 52.5059 11.1628 52.5059 10.0123C52.5059 8.86187 52.793 7.8519 53.3671 6.94445C53.9413 6.037 54.7326 5.32698 55.7412 4.8258C56.7498 4.32081 57.8942 4.07022 59.1705 4.07022C60.4468 4.07022 61.595 4.32461 62.6114 4.8258C63.6278 5.33078 64.4192 6.0332 64.9855 6.93686C65.5519 7.84051 65.8351 8.86566 65.8351 10.0161C65.8351 11.1666 65.5519 12.1955 64.9855 13.1068C64.4192 14.0142 63.6278 14.7242 62.6114 15.2368C61.595 15.7494 60.4468 16.0038 59.1705 16.0038C57.8942 16.0038 56.7692 15.7494 55.7529 15.2368V15.233ZM60.955 12.0512C61.4205 11.5614 61.6532 10.8818 61.6532 10.0161C61.6532 9.15043 61.4205 8.49737 60.955 8.01137C60.4895 7.52917 59.8959 7.28617 59.1705 7.28617C58.4451 7.28617 57.8477 7.52917 57.386 8.01137C56.9205 8.49357 56.6878 9.16182 56.6878 10.0161C56.6878 10.8704 56.9205 11.5614 57.386 12.0512C57.8516 12.541 58.4451 12.7878 59.1705 12.7878C59.8959 12.7878 60.4895 12.541 60.955 12.0512Z"
        fill="black"
      />
      <path
        d="M71.3592 15.233C70.3273 14.7204 69.5282 14.0104 68.954 13.103C68.3799 12.1955 68.0928 11.1628 68.0928 10.0123C68.0928 8.86188 68.3799 7.83673 68.954 6.93307C69.5282 6.02942 70.3273 5.327 71.3592 4.82201C72.3911 4.31703 73.5587 4.06644 74.8622 4.06644C76.1656 4.06644 77.3527 4.34741 78.3225 4.90934C79.2923 5.47128 79.9828 6.24964 80.3901 7.24062L77.1897 8.8391C76.6544 7.80255 75.8669 7.28238 74.8389 7.28238C74.0979 7.28238 73.485 7.52538 73.0001 8.00759C72.5152 8.48979 72.2708 9.15804 72.2708 10.0123C72.2708 10.8666 72.5152 11.5577 73.0001 12.0475C73.485 12.5372 74.0979 12.784 74.8389 12.784C75.8669 12.784 76.6544 12.2639 77.1897 11.2273L80.3901 12.8258C79.9828 13.8206 79.2962 14.5989 78.3225 15.1609C77.3488 15.7228 76.1966 16.0038 74.8622 16.0038C73.5277 16.0038 72.3872 15.7494 71.3592 15.2368V15.233Z"
        fill="black"
      />
      <path
        d="M93.2809 12.784V15.8101H82.3957V13.4219L87.8577 7.26339H82.547V4.26007H93.0598V6.62552L87.5978 12.784H93.2809Z"
        fill="black"
      />
      <path
        d="M108.065 11.0603H99.4878C99.6624 11.6564 99.9999 12.1158 100.5 12.4347C101.001 12.7537 101.629 12.9131 102.382 12.9131C102.948 12.9131 103.437 12.8372 103.852 12.6777C104.267 12.5183 104.682 12.2639 105.105 11.9108L107.281 14.1281C106.133 15.3773 104.457 16.0038 102.254 16.0038C100.877 16.0038 99.6624 15.7494 98.6188 15.2368C97.5753 14.7242 96.7646 14.0142 96.1904 13.1068C95.6163 12.1993 95.3292 11.1666 95.3292 10.0161C95.3292 8.86568 95.6085 7.85951 96.1671 6.95966C96.7258 6.0598 97.4977 5.34978 98.4869 4.841C99.4762 4.33222 100.586 4.07404 101.819 4.07404C103.053 4.07404 104.061 4.31324 105.02 4.78785C105.978 5.26246 106.734 5.95349 107.293 6.85334C107.852 7.757 108.131 8.82392 108.131 10.0617C108.131 10.0921 108.108 10.4224 108.065 11.0641V11.0603ZM100.237 7.47983C99.8098 7.83673 99.5344 8.31893 99.418 8.93023H104.252C104.135 8.31893 103.864 7.83673 103.433 7.47983C103.006 7.12672 102.471 6.94826 101.835 6.94826C101.199 6.94826 100.663 7.12672 100.237 7.47983Z"
        fill="black"
      />
      <path
        d="M112.503 15.6735C111.545 15.4532 110.776 15.1647 110.195 14.8116L111.436 12.0399C111.971 12.3664 112.611 12.6246 113.352 12.8182C114.093 13.0081 114.826 13.1068 115.552 13.1068C116.219 13.1068 116.692 13.0384 116.979 12.9055C117.263 12.7689 117.402 12.5752 117.402 12.3208C117.402 12.0664 117.232 11.8842 116.89 11.7779C116.549 11.6716 116.01 11.569 115.269 11.4703C114.326 11.3564 113.523 11.2083 112.864 11.0223C112.204 10.8362 111.634 10.4983 111.153 10.0009C110.672 9.50354 110.435 8.80872 110.435 7.91266C110.435 7.17606 110.66 6.51541 111.11 5.94208C111.56 5.36496 112.216 4.91313 113.081 4.57901C113.946 4.24488 114.978 4.07782 116.184 4.07782C117.041 4.07782 117.887 4.16135 118.733 4.33221C119.575 4.50307 120.277 4.74607 120.843 5.05741L119.602 7.80634C118.558 7.23681 117.426 6.95585 116.204 6.95585C115.552 6.95585 115.071 7.03178 114.768 7.17986C114.466 7.32794 114.31 7.52538 114.31 7.76458C114.31 8.03416 114.477 8.224 114.811 8.33031C115.145 8.43662 115.695 8.54673 116.463 8.66064C117.437 8.80112 118.24 8.96439 118.88 9.15044C119.52 9.33648 120.075 9.6744 120.544 10.1642C121.018 10.654 121.251 11.3412 121.251 12.2183C121.251 12.9435 121.026 13.5928 120.576 14.1661C120.126 14.7432 119.462 15.1913 118.585 15.5178C117.709 15.8443 116.65 16.0076 115.416 16.0076C114.431 16.0076 113.457 15.8975 112.499 15.6773L112.503 15.6735Z"
        fill="black"
      />
      <path
        d="M135.352 5.34977C136.244 6.20026 136.69 7.4874 136.69 9.20738V15.8139H132.555V9.86804C132.555 8.29234 131.907 7.50259 130.615 7.50259C129.905 7.50259 129.335 7.7304 128.904 8.18603C128.478 8.64165 128.264 9.32129 128.264 10.2325V15.8139H124.129V4.26006H128.07V5.51683C128.536 5.04981 129.087 4.68911 129.723 4.44231C130.363 4.19552 131.057 4.07022 131.814 4.07022C133.28 4.07022 134.459 4.49547 135.352 5.34977Z"
        fill="black"
      />
      <path
        d="M149.883 5.38015C150.915 6.25343 151.427 7.59373 151.427 9.39724V15.8101H147.575V14.318C146.982 15.4419 145.818 16 144.092 16C143.176 16 142.385 15.8443 141.718 15.533C141.05 15.2216 140.546 14.7964 140.205 14.2648C139.863 13.7333 139.693 13.1258 139.693 12.4423C139.693 11.3488 140.119 10.4983 140.977 9.89463C141.834 9.29093 143.153 8.99097 144.937 8.99097H147.288C147.214 7.76838 146.38 7.15709 144.786 7.15709C144.22 7.15709 143.646 7.24441 143.068 7.42287C142.49 7.60132 141.993 7.84432 141.586 8.15946L140.193 5.41053C140.845 4.98528 141.64 4.65495 142.575 4.41955C143.51 4.18414 144.452 4.06644 145.395 4.06644C147.354 4.06644 148.848 4.50308 149.88 5.37636L149.883 5.38015ZM146.508 13.103C146.869 12.8752 147.133 12.541 147.292 12.1006V11.0982H145.508C144.305 11.0982 143.7 11.4893 143.7 12.2715C143.7 12.6284 143.836 12.9093 144.111 13.122C144.386 13.3346 144.763 13.4409 145.244 13.4409C145.725 13.4409 146.144 13.327 146.505 13.0992L146.508 13.103Z"
        fill="black"
      />
      <path
        d="M165.746 15.6697C164.788 15.4495 164.019 15.1609 163.438 14.8078L164.679 12.0361C165.214 12.3626 165.854 12.6208 166.595 12.8144C167.336 13.0081 168.069 13.103 168.795 13.103C169.462 13.103 169.939 13.0346 170.222 12.9018C170.506 12.7689 170.645 12.5714 170.645 12.317C170.645 12.0626 170.475 11.8804 170.133 11.7741C169.792 11.6678 169.253 11.5653 168.512 11.4665C167.569 11.3526 166.766 11.2046 166.107 11.0185C165.447 10.8325 164.877 10.4945 164.396 9.99715C163.915 9.49976 163.678 8.80493 163.678 7.90887C163.678 7.17228 163.903 6.51162 164.353 5.9383C164.803 5.36117 165.459 4.90935 166.324 4.57522C167.189 4.2411 168.221 4.07404 169.427 4.07404C170.284 4.07404 171.13 4.15757 171.976 4.32843C172.818 4.49928 173.52 4.74228 174.086 5.05363L172.845 7.80256C171.801 7.23303 170.669 6.95206 169.45 6.95206C168.799 6.95206 168.318 7.028 168.015 7.17608C167.709 7.32415 167.557 7.52159 167.557 7.76079C167.557 8.03037 167.724 8.22022 168.058 8.32653C168.391 8.43284 168.942 8.54295 169.71 8.65685C170.684 8.79734 171.487 8.9606 172.127 9.14665C172.767 9.3327 173.322 9.67062 173.791 10.1604C174.265 10.6502 174.497 11.3374 174.497 12.2183C174.497 12.9435 174.272 13.5928 173.822 14.1661C173.372 14.7394 172.709 15.1913 171.832 15.5178C170.956 15.8443 169.9 16.0076 168.663 16.0076C167.678 16.0076 166.704 15.8975 165.746 15.6773V15.6697Z"
        fill="black"
      />
      <path
        d="M185.511 15.3393C185.161 15.552 184.739 15.7152 184.238 15.8291C183.738 15.943 183.202 16 182.64 16C181.1 16 179.921 15.6317 179.09 14.8913C178.26 14.1509 177.849 13.0536 177.849 11.588V7.71144H176.131V4.68533H177.849V0H181.984V4.68533H184.684V7.71144H181.984V11.5463C181.984 11.9601 182.097 12.2829 182.322 12.5145C182.547 12.7499 182.849 12.8676 183.226 12.8676C183.703 12.8676 184.126 12.7461 184.487 12.5069L185.511 15.3393Z"
        fill="black"
      />
      <path
        d="M190.344 15.233C189.328 14.7205 188.533 14.0104 187.958 13.103C187.384 12.1955 187.097 11.1628 187.097 10.0123C187.097 8.8619 187.384 7.85193 187.958 6.94448C188.533 6.03703 189.324 5.32701 190.333 4.82583C191.341 4.32464 192.486 4.07025 193.762 4.07025C195.038 4.07025 196.186 4.32464 197.203 4.82583C198.219 5.33081 199.01 6.03323 199.577 6.93689C200.143 7.84054 200.426 8.86569 200.426 10.0161C200.426 11.1666 200.143 12.1955 199.577 13.1068C199.01 14.018 198.219 14.7243 197.203 15.2368C196.186 15.7494 195.038 16.0038 193.762 16.0038C192.486 16.0038 191.361 15.7494 190.344 15.2368V15.233ZM195.55 12.0475C196.016 11.5577 196.248 10.878 196.248 10.0123C196.248 9.14666 196.016 8.4936 195.55 8.0076C195.085 7.5254 194.491 7.2824 193.766 7.2824C193.04 7.2824 192.443 7.5254 191.981 8.0076C191.516 8.48981 191.283 9.15805 191.283 10.0123C191.283 10.8666 191.516 11.5577 191.981 12.0475C192.447 12.5373 193.04 12.7803 193.766 12.7803C194.491 12.7803 195.085 12.5373 195.55 12.0475Z"
        fill="black"
      />
      <path
        d="M215.967 0V15.8102H212.025V14.6597C211.257 15.5558 210.101 16.0038 208.565 16.0038C207.491 16.0038 206.513 15.757 205.625 15.2672C204.736 14.7774 204.038 14.0788 203.526 13.1675C203.01 12.2601 202.754 11.2084 202.754 10.0123C202.754 8.81633 203.01 7.77219 203.526 6.86853C204.042 5.96868 204.74 5.27385 205.625 4.79165C206.509 4.30944 207.491 4.06645 208.565 4.06645C209.973 4.06645 211.059 4.46512 211.831 5.25866V0H215.967ZM211.199 12.0475C211.665 11.5577 211.897 10.878 211.897 10.0123C211.897 9.14666 211.669 8.49359 211.211 8.00759C210.753 7.52539 210.163 7.28239 209.438 7.28239C208.713 7.28239 208.115 7.52539 207.653 8.00759C207.188 8.4898 206.955 9.15804 206.955 10.0123C206.955 10.8666 207.188 11.5577 207.653 12.0475C208.119 12.5373 208.713 12.7803 209.438 12.7803C210.163 12.7803 210.738 12.5373 211.199 12.0475Z"
        fill="black"
      />
      <path
        d="M222.216 15.233C221.2 14.7205 220.405 14.0104 219.831 13.103C219.256 12.1955 218.969 11.1628 218.969 10.0123C218.969 8.8619 219.256 7.85193 219.831 6.94448C220.405 6.03703 221.196 5.32701 222.205 4.82583C223.213 4.32084 224.358 4.07025 225.634 4.07025C226.91 4.07025 228.058 4.32464 229.075 4.82583C230.091 5.32701 230.883 6.03323 231.449 6.93689C232.015 7.84054 232.299 8.86569 232.299 10.0161C232.299 11.1666 232.015 12.1955 231.449 13.1068C230.883 14.018 230.091 14.7243 229.075 15.2368C228.058 15.7494 226.91 16.0038 225.634 16.0038C224.358 16.0038 223.233 15.7494 222.216 15.2368V15.233ZM227.418 12.0475C227.884 11.5577 228.117 10.878 228.117 10.0123C228.117 9.14666 227.884 8.4936 227.418 8.0076C226.953 7.5254 226.359 7.2824 225.634 7.2824C224.909 7.2824 224.311 7.5254 223.849 8.0076C223.384 8.48981 223.151 9.15805 223.151 10.0123C223.151 10.8666 223.384 11.5577 223.849 12.0475C224.315 12.5373 224.909 12.7803 225.634 12.7803C226.359 12.7803 226.953 12.5373 227.418 12.0475Z"
        fill="black"
      />
      <path
        d="M253.452 5.38015C254.484 6.25343 254.996 7.59373 254.996 9.39724V15.8101H251.144V14.318C250.551 15.4419 249.387 16 247.66 16C246.745 16 245.954 15.8443 245.286 15.5292C244.619 15.2178 244.115 14.7926 243.773 14.261C243.432 13.7295 243.261 13.122 243.261 12.4385C243.261 11.345 243.688 10.4983 244.545 9.89463C245.403 9.29093 246.722 8.99097 248.506 8.99097H250.857C250.783 7.76838 249.949 7.15709 248.355 7.15709C247.788 7.15709 247.214 7.24441 246.636 7.42287C246.058 7.60132 245.562 7.84432 245.154 8.15946L243.762 5.41053C244.413 4.98528 245.209 4.65495 246.144 4.41955C247.079 4.18414 248.021 4.06644 248.964 4.06644C250.923 4.06644 252.416 4.50308 253.448 5.37636L253.452 5.38015ZM250.081 13.103C250.442 12.8752 250.706 12.541 250.865 12.1006V11.0982H249.08C247.878 11.0982 247.273 11.4893 247.273 12.2715C247.273 12.6284 247.408 12.9093 247.684 13.122C247.959 13.3346 248.335 13.4409 248.816 13.4409C249.298 13.4409 249.716 13.327 250.077 13.0992L250.081 13.103Z"
        fill="black"
      />
      <path
        d="M242.307 6.13574L240.938 4.21832L239.805 5.04604V0H235.67V8.06075L233.164 9.88704L234.56 11.7817L235.67 10.9729V15.8102H239.805V7.96203L242.307 6.13574Z"
        fill="black"
      />
    </svg>

    <!-- MOBILE LOGO -->
    <svg
      alt="Logo"
      class="h-7 w-auto block lg:hidden"
      width="113"
      height="28"
      viewBox="0 0 113 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.02788 1.8352C9.70872 2.49969 10.0519 3.50733 10.0519 4.84721V10.0107H6.88734V5.36464C6.88734 4.13369 6.39169 3.51822 5.40585 3.51822C4.86118 3.51822 4.42544 3.69796 4.09864 4.05199C3.77184 4.40603 3.60844 4.9398 3.60844 5.65332V10.0161H0.438477V0.985515H3.45049V1.96592C3.80452 1.60099 4.22936 1.31776 4.71412 1.12713C5.20976 0.931048 5.74354 0.833008 6.32089 0.833008C7.4429 0.833008 8.3416 1.16526 9.02788 1.8352Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2078 9.55858C13.4289 9.16098 12.8243 8.60541 12.3832 7.8919C11.942 7.18383 11.7241 6.37772 11.7241 5.47902C11.7241 4.58032 11.942 3.79055 12.3832 3.08248C12.8243 2.37442 13.4289 1.81886 14.1969 1.4267C14.9703 1.03453 15.8418 0.833008 16.8222 0.833008C17.8026 0.833008 18.6741 1.02909 19.453 1.4267C20.2318 1.81886 20.8364 2.36897 21.2667 3.07704C21.697 3.78511 21.9149 4.58577 21.9149 5.48447C21.9149 6.38317 21.697 7.18928 21.2667 7.89735C20.8364 8.60541 20.2264 9.16098 19.453 9.56403C18.6741 9.96164 17.7972 10.1632 16.8222 10.1632C15.8473 10.1632 14.9867 9.96164 14.2078 9.56403M18.1893 7.06945C18.5434 6.68818 18.7231 6.15441 18.7231 5.47902C18.7231 4.80363 18.5434 4.29165 18.1893 3.91583C17.8353 3.54001 17.3778 3.34937 16.8222 3.34937C16.2666 3.34937 15.8091 3.54001 15.4551 3.91583C15.1011 4.29165 14.9213 4.81453 14.9213 5.47902C14.9213 6.14352 15.1011 6.68818 15.4551 7.06945C15.8091 7.45072 16.2666 7.64135 16.8222 7.64135C17.3778 7.64135 17.8298 7.45072 18.1893 7.06945Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.4508 0.985352L35.2373 10.0105H32.1708L30.4714 5.09759L28.7067 10.0105H25.6457L22.4321 0.985352H25.4278L27.2742 6.44837L29.1915 0.985352H31.8876L33.7558 6.49739L35.6676 0.985352H38.4508Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.4466 9.55858C40.6677 9.16098 40.0631 8.60541 39.6219 7.8919C39.1862 7.18383 38.9629 6.37772 38.9629 5.47902C38.9629 4.58032 39.1808 3.79055 39.6219 3.08248C40.0631 2.37442 40.6677 1.81886 41.4357 1.4267C42.2091 1.03453 43.0806 0.833008 44.061 0.833008C45.0414 0.833008 45.9128 1.02909 46.6917 1.4267C47.4706 1.81886 48.0752 2.36897 48.5055 3.07704C48.9358 3.78511 49.1536 4.58577 49.1536 5.48447C49.1536 6.38317 48.9358 7.18928 48.5055 7.89735C48.0697 8.60541 47.4651 9.16098 46.6917 9.56403C45.9128 9.96164 45.0359 10.1632 44.061 10.1632C43.086 10.1632 42.2254 9.96164 41.4466 9.56403M45.4226 7.06945C45.7767 6.68818 45.9564 6.15441 45.9564 5.47902C45.9564 4.80363 45.7767 4.29165 45.4226 3.91583C45.0686 3.54001 44.6111 3.34937 44.0555 3.34937C43.5 3.34937 43.0424 3.54001 42.6884 3.91583C42.3344 4.29165 42.1546 4.81453 42.1546 5.47902C42.1546 6.14352 42.3344 6.68818 42.6884 7.06945C43.0424 7.45072 43.5 7.64135 44.0555 7.64135C44.6111 7.64135 45.0632 7.45072 45.4226 7.06945Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.0318 9.55866C52.242 9.16105 51.632 8.60549 51.1908 7.89198C50.7496 7.18391 50.5317 6.3778 50.5317 5.4791C50.5317 4.5804 50.7496 3.77974 51.1908 3.07167C51.6265 2.36905 52.242 1.81893 53.0318 1.42132C53.8215 1.02916 54.7148 0.827637 55.7115 0.827637C56.7083 0.827637 57.6179 1.0455 58.3586 1.48668C59.1048 1.92242 59.6277 2.53245 59.9381 3.31132L57.4926 4.55861C57.0841 3.74706 56.4795 3.344 55.6952 3.344C55.1287 3.344 54.6603 3.53463 54.2899 3.91046C53.9196 4.28628 53.7344 4.80916 53.7344 5.47365C53.7344 6.13815 53.9196 6.68281 54.2899 7.06408C54.6603 7.44535 55.1287 7.63598 55.6952 7.63598C56.485 7.63598 57.0841 7.23293 57.4926 6.42137L59.9381 7.66866C59.6277 8.44754 59.0994 9.05212 58.3586 9.4933C57.6124 9.93448 56.7301 10.1523 55.7115 10.1523C54.693 10.1523 53.8215 9.95082 53.0318 9.55321"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.8021 7.64664V10.0105H61.4741V8.14228L65.6572 3.33287H61.5939V0.985352H69.6387V2.83177L65.4556 7.64664H69.8021Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.5263 6.29602H73.9631C74.0938 6.76444 74.3553 7.11847 74.7365 7.36902C75.1178 7.61957 75.5971 7.74484 76.1799 7.74484C76.6102 7.74484 76.986 7.68493 77.3019 7.55965C77.6178 7.43983 77.9392 7.2383 78.2605 6.96052L79.9272 8.69256C79.0503 9.66752 77.7703 10.1577 76.0818 10.1577C75.0252 10.1577 74.0993 9.95619 73.2986 9.55858C72.4979 9.16098 71.8825 8.60541 71.4413 7.8919C71.0055 7.18383 70.7822 6.37772 70.7822 5.47902C70.7822 4.58032 70.9947 3.796 71.4249 3.08793C71.8498 2.38531 72.4435 1.82975 73.2006 1.43214C73.9522 1.03453 74.8019 0.833008 75.7441 0.833008C76.6864 0.833008 77.4599 1.01819 78.1952 1.38857C78.9305 1.75894 79.5078 2.29816 79.9381 3.00623C80.3629 3.7143 80.5808 4.54764 80.5808 5.5117C80.5808 5.53349 80.5645 5.79493 80.5318 6.29602M74.535 3.49643C74.2082 3.77421 74.0012 4.15003 73.9086 4.62934H77.6069C77.5198 4.15003 77.3073 3.77421 76.9805 3.49643C76.6537 3.21865 76.2452 3.08248 75.755 3.08248C75.2648 3.08248 74.8563 3.2241 74.5295 3.49643H74.535Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.566 9.90172C82.8361 9.72743 82.2425 9.50412 81.8013 9.22634L82.749 7.064C83.1575 7.32 83.6477 7.52153 84.2141 7.67403C84.7806 7.82109 85.3416 7.89735 85.8972 7.89735C86.4092 7.89735 86.7686 7.84288 86.9865 7.73939C87.2044 7.63591 87.3133 7.4834 87.3133 7.28187C87.3133 7.08035 87.1826 6.93873 86.9211 6.85703C86.6597 6.77533 86.2458 6.69363 85.6793 6.61738C84.9549 6.53023 84.3449 6.4104 83.8383 6.26879C83.3318 6.12718 82.896 5.86029 82.5311 5.46813C82.1662 5.08142 81.981 4.53675 81.981 3.83413C81.981 3.25678 82.1553 2.74479 82.4984 2.29272C82.8416 1.84064 83.3481 1.48661 84.0072 1.22517C84.6717 0.963728 85.4614 0.833008 86.3819 0.833008C87.0355 0.833008 87.6891 0.898368 88.3318 1.03453C88.9745 1.16526 89.5138 1.35589 89.944 1.60099L88.9963 3.74698C88.1957 3.30035 87.3296 3.08248 86.3983 3.08248C85.8972 3.08248 85.5322 3.1424 85.298 3.25678C85.0638 3.37116 84.9494 3.52367 84.9494 3.7143C84.9494 3.92672 85.0747 4.07378 85.3307 4.15548C85.5867 4.23718 86.0061 4.32433 86.5943 4.41147C87.3405 4.52041 87.956 4.65113 88.4408 4.79274C88.931 4.93435 89.3558 5.20124 89.7153 5.58251C90.0748 5.96378 90.2545 6.503 90.2545 7.18928C90.2545 7.75573 90.0802 8.26227 89.7371 8.71435C89.3939 9.16642 88.8819 9.51501 88.212 9.771C87.5421 10.027 86.7305 10.1523 85.7882 10.1523C85.0311 10.1523 84.2904 10.0651 83.5551 9.89628"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100.576 1.83512C101.257 2.49962 101.6 3.50725 101.6 4.84713V10.0106H98.4351V5.36457C98.4351 4.13362 97.9395 3.51814 96.9536 3.51814C96.409 3.51814 95.9732 3.69788 95.6464 4.05192C95.3196 4.40595 95.1562 4.93973 95.1562 5.65324V10.016H91.9917V0.985439H95.0037V1.96584C95.3577 1.60091 95.7826 1.31769 96.2673 1.12705C96.7575 0.930972 97.2913 0.838379 97.8687 0.838379C98.9907 0.838379 99.8894 1.17063 100.576 1.83512Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M111.104 1.85698C111.894 2.53782 112.286 3.58903 112.286 4.99427V10.0052H109.34V8.83962C108.882 9.71654 107.994 10.1523 106.676 10.1523C105.979 10.1523 105.374 10.0324 104.862 9.78734C104.35 9.54224 103.964 9.21544 103.702 8.79605C103.441 8.3821 103.31 7.90279 103.31 7.37447C103.31 6.51934 103.637 5.85484 104.29 5.38643C104.944 4.91257 105.957 4.67836 107.319 4.67836H109.116C109.062 3.72519 108.424 3.24589 107.199 3.24589C106.769 3.24589 106.328 3.31669 105.881 3.45286C105.434 3.58903 105.058 3.78511 104.748 4.02476L103.68 1.87877C104.182 1.54652 104.786 1.28508 105.505 1.10534C106.235 0.925601 106.954 0.833008 107.673 0.833008C109.171 0.833008 110.314 1.17615 111.104 1.85698ZM108.523 7.89735C108.8 7.71761 109.002 7.45617 109.122 7.11303V6.3287H107.755C106.834 6.3287 106.371 6.63372 106.371 7.24375C106.371 7.52153 106.475 7.74484 106.687 7.90824C106.899 8.07709 107.188 8.15879 107.553 8.15879C107.918 8.15879 108.239 8.07164 108.517 7.8919L108.523 7.89735Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.05086 27.1189C1.321 26.9446 0.73276 26.7213 0.286133 26.4435L1.23385 24.2811C1.64236 24.5371 2.13256 24.7387 2.69901 24.8857C3.26546 25.0382 3.82647 25.109 4.37659 25.109C4.88857 25.109 5.24805 25.0546 5.46592 24.9511C5.68379 24.8476 5.79272 24.6951 5.79272 24.4936C5.79272 24.292 5.662 24.1504 5.40056 24.0687C5.13912 23.987 4.72517 23.9053 4.16417 23.8291C3.4452 23.7419 2.82973 23.6221 2.32864 23.4805C1.8221 23.3334 1.39181 23.072 1.02143 22.6798C0.656507 22.2931 0.47132 21.7484 0.47132 21.0513C0.47132 20.4739 0.645613 19.9619 0.988754 19.5153C1.33189 19.0687 1.83299 18.7092 2.49204 18.4532C3.15108 18.1918 3.94085 18.061 4.86134 18.061C5.51494 18.061 6.1631 18.1264 6.8058 18.2626C7.44851 18.3933 7.98773 18.5839 8.41802 18.829L7.4703 20.975C6.66964 20.5338 5.80906 20.3105 4.87768 20.3105C4.37659 20.3105 4.01166 20.3704 3.77745 20.4848C3.54325 20.5992 3.42886 20.7517 3.42886 20.9423C3.42886 21.1547 3.55414 21.3018 3.81013 21.3835C4.06613 21.4652 4.48552 21.5524 5.07376 21.6395C5.81451 21.7484 6.42998 21.8792 6.92018 22.0208C7.41039 22.1624 7.82978 22.4293 8.19471 22.8105C8.55419 23.1918 8.73393 23.7256 8.73393 24.4173C8.73393 24.9838 8.56508 25.4903 8.21649 25.9369C7.87335 26.3836 7.36681 26.7376 6.69687 26.9936C6.02693 27.2496 5.22082 27.3749 4.27855 27.3749C3.52691 27.3749 2.78071 27.2877 2.05086 27.1189Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8006 26.8626C16.5338 27.0314 16.2124 27.1567 15.8257 27.2438C15.4444 27.331 15.0359 27.3746 14.6056 27.3746C13.4291 27.3746 12.525 27.0859 11.8932 26.5085C11.2614 25.9312 10.9455 25.0706 10.9455 23.9323V20.9039H9.63281V18.54H10.9455V14.8799H14.1045V18.54H16.1688V20.9039H14.1045V23.8996C14.1045 24.2209 14.1917 24.4715 14.3605 24.6567C14.5348 24.8364 14.7636 24.929 15.0523 24.929C15.4172 24.929 15.7385 24.8364 16.0163 24.6458L16.7952 26.8571L16.8006 26.8626Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1394 26.7811C19.3605 26.3835 18.7559 25.8279 18.3202 25.1199C17.8844 24.4118 17.6611 23.6057 17.6611 22.707C17.6611 21.8083 17.879 21.024 18.3202 20.3104C18.7559 19.6024 19.3605 19.0468 20.1339 18.6546C20.9074 18.2625 21.7788 18.0664 22.7538 18.0664C23.7287 18.0664 24.6056 18.2625 25.3791 18.6546C26.1525 19.0468 26.7571 19.5969 27.1928 20.2995C27.6231 21.0022 27.841 21.8028 27.841 22.7015C27.841 23.6002 27.6231 24.4009 27.1928 25.1144C26.7625 25.8225 26.1579 26.378 25.3791 26.7756C24.6056 27.1732 23.7287 27.3748 22.7538 27.3748C21.7788 27.3748 20.9182 27.1732 20.1448 26.7756M24.11 24.292C24.464 23.9107 24.6438 23.3824 24.6438 22.7015C24.6438 22.0207 24.464 21.5142 24.11 21.1383C23.756 20.7625 23.2984 20.5719 22.7483 20.5719C22.1982 20.5719 21.7407 20.7625 21.3812 21.1383C21.0272 21.5142 20.8474 22.037 20.8474 22.7015C20.8474 23.366 21.0272 23.9052 21.3812 24.292C21.7352 24.6732 22.1928 24.8639 22.7483 24.8639C23.3039 24.8639 23.756 24.6732 24.11 24.292Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.2406 14.8911V27.2333H36.2286V26.3346C35.6404 27.0318 34.758 27.3803 33.587 27.3803C32.7645 27.3803 32.0183 27.1897 31.343 26.8084C30.6676 26.4272 30.1338 25.8825 29.7362 25.169C29.344 24.4609 29.1479 23.6385 29.1479 22.7071C29.1479 21.7757 29.344 20.9587 29.7362 20.2561C30.1283 19.5535 30.6621 19.0142 31.343 18.633C32.0183 18.2572 32.77 18.0665 33.587 18.0665C34.66 18.0665 35.4933 18.377 36.0816 18.9979V14.8911H39.2406ZM35.5968 24.2921C35.9508 23.9108 36.1306 23.3825 36.1306 22.7016C36.1306 22.0208 35.9563 21.5143 35.6077 21.1385C35.2591 20.7626 34.807 20.572 34.2515 20.572C33.6959 20.572 33.2438 20.7626 32.8844 21.1385C32.5303 21.5143 32.3506 22.0372 32.3506 22.7016C32.3506 23.3661 32.5303 23.9054 32.8844 24.2921C33.2384 24.6733 33.6959 24.864 34.2515 24.864C34.807 24.864 35.2428 24.6733 35.5968 24.2921Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.4294 26.7811C42.6505 26.3835 42.046 25.8279 41.6102 25.1199C41.1745 24.4118 40.9512 23.6057 40.9512 22.707C40.9512 21.8083 41.169 21.024 41.6102 20.3104C42.046 19.6024 42.6505 19.0468 43.424 18.6546C44.1974 18.2625 45.0689 18.0664 46.0438 18.0664C47.0188 18.0664 47.8957 18.2625 48.6691 18.6546C49.4425 19.0468 50.0471 19.5969 50.4829 20.2995C50.9131 21.0022 51.131 21.8028 51.131 22.7015C51.131 23.6002 50.9131 24.4009 50.4829 25.1144C50.0526 25.8225 49.448 26.378 48.6691 26.7756C47.8957 27.1732 47.0188 27.3748 46.0438 27.3748C45.0689 27.3748 44.2083 27.1732 43.4349 26.7756M47.4 24.292C47.7541 23.9107 47.9338 23.3824 47.9338 22.7015C47.9338 22.0207 47.7541 21.5142 47.4 21.1383C47.046 20.7625 46.5885 20.5719 46.0384 20.5719C45.4883 20.5719 45.0307 20.7625 44.6767 21.1383C44.3227 21.5142 44.1429 22.037 44.1429 22.7015C44.1429 23.366 44.3227 23.9052 44.6767 24.292C45.0307 24.6732 45.4883 24.8639 46.0384 24.8639C46.5885 24.8639 47.046 24.6732 47.4 24.292Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.8229 19.0905C67.6126 19.7713 68.0048 20.8171 68.0048 22.2277V27.2332H65.0636V26.0677C64.6061 26.9446 63.7237 27.3803 62.4001 27.3803C61.703 27.3803 61.0984 27.2605 60.5864 27.0154C60.0744 26.7703 59.6877 26.4435 59.4317 26.0241C59.1703 25.6101 59.0396 25.1363 59.0396 24.6025C59.0396 23.7474 59.3663 23.0883 60.0199 22.6145C60.6736 22.1406 61.6812 21.9064 63.0483 21.9064H64.8457C64.7912 20.9532 64.154 20.4739 62.9339 20.4739C62.5036 20.4739 62.0625 20.5447 61.6213 20.6809C61.1801 20.8171 60.7988 21.0131 60.4884 21.2528L59.4263 19.1068C59.9274 18.7745 60.5319 18.5186 61.2455 18.3334C61.959 18.1482 62.6779 18.061 63.3969 18.061C64.8947 18.061 66.0331 18.4042 66.8229 19.085M64.2466 25.1199C64.5244 24.9402 64.7259 24.6842 64.8457 24.3356V23.5567H63.484C62.5635 23.5567 62.106 23.8617 62.106 24.4718C62.106 24.7496 62.2095 24.9729 62.4219 25.1363C62.6344 25.3051 62.923 25.3868 63.288 25.3868C63.6529 25.3868 63.9742 25.2997 64.252 25.1199H64.2466Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.5438 19.6787L57.4925 18.1809L56.632 18.8236V14.8911H53.4674V21.182L51.5557 22.6036L52.6232 24.0851L53.4674 23.4533V27.2278H56.632V21.1003L58.5438 19.6787Z"
        fill="black"
      />
    </svg>
  </NuxtLink>
</template>
